<template>
  <div class="articles" style="background-image: url('./images/back.svg')">
    <article-grid  v-if="articles.length" :articles="articles" />
    <base-icon
        v-else
        class="icon"
        :name="$vuetify.breakpoint.width > 800 ? 'statya' : 'statyatablet' "
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import ArticleGrid from '@/components/articles/ArticleGrid';

export default {
  name: 'Articles',
  components: {
    ArticleGrid,
  },
  computed: {
    ...mapState('articles', ['articles']),
  },
  created() {
    this.UPDATE_FILTERS({ pub_date__lte: new Date() });
    this.getAll();
  },
  methods: {
    ...mapMutations('articles', ['UPDATE_FILTERS']),
    ...mapActions('articles', ['getAll']),
  },
};
</script>

<style lang="sass">
  .articles
    background-size: cover
    .icon
      width: 100vw
      height: calc(100vh - 50px)
</style>
