<template>
  <v-container>
    <v-row v-if="articles.length">
      <v-col v-for="(article, key) in articles" :key="key" :cols="calcFlex(key)"  >
        <article-card
          :img="article.image"
          :title="article.title"
          :author="article.author"
          :pub_date="formatDate(article.pub_date)"
          @click.native="push(article.id)"
        />
      </v-col>
      <v-col v-intersect.quiet="{ handler: infiniteScrolling, thresholds: [1] }" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ArticleCard from '@/components/articles/ArticleCard';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { articles } from '@/api';
import debounce from '@/common/decorators/debounce';

export default {
  name: 'ArticleGrid',
  components: {
    ArticleCard,
  },
  data: () => ({
    offset: 0,
  }),
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('articles', ['pageInfo']),
  },
  created() {
    dayjs.extend(relativeTime);
  },
  methods: {
    ...mapMutations('articles', ['SET_ARTICLES']),
    ...mapActions('articles', ['getAll']),
    infiniteScrolling: (debounce(async function handler() {
      if (this.pageInfo.total <= this.pageInfo.limit * this.offset) {
        return;
      }
      this.offset += this.pageInfo.limit;

      const { data } = await articles.getAll({ offset: this.offset, order_by: '-pub_date' });
      this.SET_ARTICLES(this.articles.concat(data.items));
    }, 1000)),
    formatDate(date) {
      return dayjs(date).locale('ru').fromNow();
    },
    push(id) {
      this.$router.push({ name: 'Article', params: { id } });
    },
    calcFlex(index) {
      if (this.$vuetify.breakpoint.mobile) {
        return 0;
      }
      if (index === 0) return 7;
      return index > 1 ? 4 : 5;
    },
  },
};
</script>

<style scoped>

</style>
