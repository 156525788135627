export default function (cb, wait) {
  let timer = null;
  return function handler(...args) {
    if (timer) {
      clearTimeout(timer);
    }
    const context = this;
    timer = setTimeout(() => cb.apply(context, args), wait);
  };
}
