<template>
 <v-card light class="mx-auto d-flex flex-column" min-height="400" style="cursor: pointer">
   <v-img
     :src="getImage(img)"
     class="white--text align-end"
     gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
     height="180"
   >
     <template v-slot:placeholder>
       <v-row
         class="fill-height ma-0"
         align="center"
         justify="center"
       >
         <v-progress-circular
           indeterminate
           color="grey lighten-5"
         ></v-progress-circular>
       </v-row>
     </template>
   </v-img>
   <v-card-title v-text="title" />
   <v-card-actions>
     <v-list-item class="grow">
       <v-list-item-avatar color="grey darken-3">
         <v-img
           class="elevation-6"
           alt=""
           :src="getImage(author.profile.avatar )"
         >
           <template v-slot:placeholder>
             <v-row
               class="fill-height ma-0"
               align="center"
               justify="center"
             >
               <v-progress-circular
                 indeterminate
                 color="grey lighten-5"
               ></v-progress-circular>
             </v-row>
           </template>
         </v-img>
       </v-list-item-avatar>
       <v-list-item-content>
         <v-list-item-title v-text="`${author.first_name} ${author.last_name}`" />
         <v-list-item-subtitle v-text="pub_date" />
       </v-list-item-content>
     </v-list-item>
   </v-card-actions>
 </v-card>
</template>

<script>
import getImage from '@/common/mixins/getImage';

export default {
  name: 'ArticleCard',
  mixins: [getImage],
  props: {
    img: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    author: {
      type: Object,
      default: () => ({
        first_name: '',
        last_name: '',
      }),
    },
    pub_date: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style scoped>

</style>
